/* Import separated styles */
@import './styles/global/colors.css';
@import './styles/global/buttons.css';

body {
  margin: 0 auto;
  padding: 0;
  min-height: 80vh;
  background: linear-gradient(135deg, var(--bg-primary) 0%, var(--bg-secondary) 100%);
}

main {
  padding-top: 15vh;
  min-height: 100vh;
  color: var(--text-primary);
  background: linear-gradient(135deg, var(--bg-primary) 0%, var(--bg-secondary) 100%);
  display: flex;
  flex-direction: column;
}

/* Layout utilities */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-4 {
  gap: 2rem;
}

/* Common components */
.paper {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: var(--shadow-md);
  padding: 1.5rem;
}

.paper-dark {
  background-color: var(--bg-primary);
}

.paper-light {
  background-color: var(--bg-tertiary);
}

/* Typography */
.title {
  color: var(--text-primary);
  font-weight: 600;
  margin-bottom: 1rem;
}

.title-lg {
  font-size: 2rem;
}

.title-md {
  font-size: 1.5rem;
}

.title-sm {
  font-size: 1.25rem;
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.text-center {
  text-align: center;
}

/* Utility classes */
.hover-elevate {
  transition: all 0.2s ease-in-out;
}

.hover-elevate:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-4 { margin-top: 2rem; }
.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 2rem; }

.p-1 { padding: 0.5rem; }
.p-2 { padding: 1rem; }
.p-4 { padding: 2rem; }

.w-full { width: 100%; }
.h-full { height: 100%; }

.relative { position: relative; }
.absolute { position: absolute; }
.fixed { position: fixed; }
