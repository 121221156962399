.practice-page {
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
  background-color: var(--bg-secondary);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
}

.practice-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem;
}

.back-button {
  margin-top: 4rem;
  position: absolute;
  left: 0;
}

.back-button svg {
  fill: var(--text-primary);
}

.practice-title {
  color: var(--text-primary);
  margin-bottom: 1.5rem;
  font-size: 2rem !important;
  font-weight: 500 !important;
}

.progress-section {
  margin-bottom: 2rem;
}

.progress-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1rem;
  color: var(--text-primary);
}

.time-display {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.current-time, .best-time {
  color: var(--text-primary);
}

.best-time {
  color: var(--primary-color);
}

.progress-bar {
  height: 6px;
  border-radius: 3px;
}

.content-box {
  text-align: center;
  margin: 2rem auto;
  max-width: 800px;
}

/* Multiple Choice Styling */
.radio-group {
  margin: 2rem 0;
}

.radio-option {
  margin: 0.5rem 0 !important;
}

.radio-option .MuiRadio-root {
  color: var(--text-primary);
}

.radio-option .MuiRadio-root.Mui-checked {
  color: var(--primary-color);
}

.radio-option .MuiFormControlLabel-label {
  font-size: 2rem !important;
  color: var(--text-primary);
}

/* Submit button styling with increased specificity */
.content-box .submit-button.MuiButton-root {
  margin-top: 2rem !important;
  padding: 0.5rem 2rem !important;
  font-size: 1.2rem !important;
  background-color: var(--primary-color) !important;
  color: var(--text-primary) !important;
}

.content-box .submit-button.MuiButton-root:hover {
  background-color: var(--primary-light) !important;
}

.content-box .submit-button.MuiButton-root:disabled {
  opacity: 0.5;
  background-color: var(--border-color) !important;
}

/* Number Input Styling */
.number-input {
  margin: 0.25rem !important;
  width: 70px !important;
}

.number-input input {
  width: 100% !important;
  color: var(--text-primary) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  text-align: center;
  padding: 8px !important;
  font-size: 1rem !important;
  font-family: 'Courier New', monospace !important;
  border: 2px solid var(--border-color) !important;
  border-radius: 4px !important;
  min-width: 0 !important;
}

.number-input input:focus {
  outline: none !important;
  border-color: var(--primary-color) !important;
}

.number-input::before,
.number-input::after {
  display: none !important;
}

.grid-container {
  display: flex !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  gap: 0.25rem !important;
  margin-bottom: 0.5rem !important;
  width: 100% !important;
}

.grid-container .MuiGrid-item {
  flex: 0 0 auto !important;
  width: auto !important;
}

/* Vertical Operation Styling */
.vertical-operation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  min-height: 200px;
}

.operation-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Courier New', monospace;
  position: relative;
  padding: 0 0.5rem;
  width: 120px;
}

.operation-numbers .number {
  font-size: 2.5rem !important;
  line-height: 1.2 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  margin: 0 !important;
  min-width: 3ch;
  text-align: right;
}

/* Feedback dialog vertical operation styles */
.feedback-vertical-operation .number {
  font-size: 1.2rem !important;
  position: relative;
}

.feedback-vertical-operation .number.strike {
  text-decoration: line-through;
}

.feedback-vertical-operation .number.modified {
  color: var(--text-secondary) !important;
  text-decoration: line-through;
}

.feedback-vertical-operation .number.modified::after {
  content: attr(data-modified);
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
  color: var(--text-primary);
}

.feedback-vertical-operation .carry-number {
  font-size: 1rem !important;
  color: var(--text-secondary) !important;
}

.feedback-vertical-operation .answer-number {
  color: var(--success-color) !important;
}

.feedback-vertical-operation .intermediate-number {
  color: var(--text-secondary) !important;
}

/* Long Division Feedback Styling */
.feedback-long-division {
  font-family: monospace;
  font-size: 1.2rem;
  padding: 1rem;
  position: relative;
}

.feedback-long-division .division-structure {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}

.feedback-long-division .divisor {
  font-size: 1.2rem;
  color: var(--text-primary);
  margin-right: 0.5rem;
}

.feedback-long-division .quotient-row {
  position: relative;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
}

.feedback-long-division .quotient-line {
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  right: 0;
  border-bottom: 2px solid var(--text-primary);
}

.feedback-long-division .division-bracket {
  position: absolute;
  left: 0;
  top: 2.5rem;
  width: 1.5rem;
  height: 3rem;
  border-left: 2px solid var(--text-primary);
  border-top: 2px solid var(--text-primary);
}

.feedback-long-division .steps-container {
  margin-left: 1.5rem;
  position: relative;
  padding-top: 0.5rem;
}

.feedback-long-division .step {
  margin-bottom: 1rem;
}

.feedback-long-division .step-line {
  border-top: 2px solid var(--text-primary);
  margin: 0.5rem 0;
  width: 100%;
}

.feedback-long-division .number {
  width: 32px;
  height: 32px;
  margin: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--text-primary);
}

.feedback-long-division .quotient-number {
  color: var(--success-color) !important;
}

.feedback-long-division .remainder-number {
  color: var(--text-secondary) !important;
}

.feedback-long-division .subtracted-number {
  color: var(--text-secondary) !important;
}

.operation-line-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: relative;
}

.operation-symbol {
  font-size: 2rem !important;
  line-height: 1 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  margin: 0 !important;
  position: absolute;
  left: -1.5rem;
}

.operation-line {
  width: 100%;
  height: 3px;
  background-color: var(--text-primary);
  margin: 0.5rem 0;
}

/* Level Complete Dialog Styling */
.level-complete-dialog {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary);
  border: 2px solid var(--border-color);
  border-radius: 10px;
  min-width: 300px;
  padding: 20px;
}

.level-complete-title {
  text-align: center;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: var(--primary-color) !important;
  padding: 0 !important;
}

.level-complete-content {
  text-align: center;
  font-size: 1.1rem;
  margin: 20px 0;
  padding: 0 !important;
  color: var(--text-primary) !important;
}

.level-complete-actions {
  display: flex;
  justify-content: center !important;
  padding: 20px 0 0 0 !important;
}

.continue-button {
  background-color: var(--primary-color) !important;
  color: var(--text-primary) !important;
  padding: 8px 24px !important;
  transition: none !important;
  animation: none !important;
  box-shadow: none !important;
}

.continue-button:hover {
  background-color: var(--primary-light) !important;
}

.continue-button:focus {
  background-color: var(--primary-color) !important;
  outline: none !important;
}

.continue-button:active {
  background-color: var(--primary-light) !important;
}

/* Fraction Practice Styling */
.visual-container {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 20px;
  margin: 1rem 0;
  border: 2px solid var(--border-color);
}

.fraction-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 1rem 0;
}

.fraction-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.fraction-input input {
  width: 60px;
  height: 40px;
  text-align: center;
  font-size: 1.2em;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  border: 2px solid var(--border-color);
  border-radius: 4px;
}

.fraction-input input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.fraction-input hr {
  width: 100%;
  border: none;
  border-top: 2px solid var(--text-primary);
  margin: 0;
}

@media (max-width: 480px) {
  .progress-header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .time-display {
    justify-content: center;
  }

  .practice-title {
    font-size: 1.5rem !important;
  }

  .content-box {
    padding: 0.5rem;
  }

  .number-input {
    width: 45px !important;
  }

  .number-input input {
    font-size: 1.25rem !important;
    padding: 4px !important;
  }

  .fraction-input input {
    width: 45px;
    height: 35px;
    font-size: 1rem;
  }
}
