/* Common dashboard styles */
.dashboard-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboard-paper {
  padding: 1rem;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dashboard-title {
  font-weight: 700 !important;
  color: var(--text-primary) !important;
  margin: 0 !important;
  font-size: 2rem !important;
}

.dashboard-error {
  margin-bottom: 1rem !important;
}

/* Loading styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Classes component specific styles */
.class-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.class-list-title {
  color: var(--text-primary) !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

.create-class-button {
  background-color: var(--primary-color) !important;
  color: var(--text-primary) !important;
  text-transform: none !important;
  padding: 0.5rem 1.5rem !important;
}

.create-class-button:hover {
  background-color: var(--primary-dark) !important;
}

.class-list {
  width: 100%;
  background-color: var(--bg-tertiary) !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.class-list-item {
  padding: 1rem !important;
}

.class-list-item.selected {
  background-color: var(--bg-primary) !important;
}

.class-list-item:hover {
  background-color: var(--bg-primary) !important;
}

.class-list-item .MuiTypography-root {
  color: var(--text-primary) !important;
}

.class-list-item .text-secondary {
  color: var(--text-secondary) !important;
}

.class-name {
  color: var(--text-primary) !important;
  font-weight: 500 !important;
}

.class-details {
  color: var(--text-secondary) !important;
  font-size: 0.875rem !important;
}

.class-divider {
  background-color: var(--border-color) !important;
}

/* Student Table styles */
.student-table-container {
  margin-top: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--bg-tertiary);
  position: relative;
}

.table-container {
  max-height: 440px;
  overflow: auto;
}

.table-container .MuiTable-root {
  background-color: var(--bg-tertiary) !important;
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

.header-cell {
  background-color: var(--bg-primary) !important;
  color: var(--text-primary) !important;
  font-weight: 600 !important;
  border-bottom: 1px solid var(--border-color) !important;
  white-space: nowrap;
  padding: 16px !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.header-cell .MuiTableSortLabel-root {
  color: var(--text-primary) !important;
}

.header-cell .MuiTableSortLabel-root.Mui-active {
  color: var(--primary-color) !important;
}

.header-cell .MuiTableSortLabel-icon {
  color: var(--primary-color) !important;
}

.table-row {
  background-color: var(--bg-tertiary) !important;
}

.table-row:nth-of-type(odd) {
  background-color: var(--bg-secondary) !important;
}

.table-row:hover {
  background-color: var(--bg-primary) !important;
}

.table-cell {
  color: var(--text-primary) !important;
  border-bottom: 1px solid var(--border-color) !important;
  white-space: nowrap;
  padding: 16px !important;
}

/* Teacher dashboard specific styles */
.selected-class-container {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
}

.class-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.selected-class-title {
  color: var(--text-primary) !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  margin: 0 !important;
}

/* Grade select styles */
.grade-select {
  min-width: 120px !important;
}

.grade-select .MuiOutlinedInput-root {
  background-color: var(--bg-tertiary);
  color: var(--text-primary);
}

.grade-select .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

.grade-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}

.grade-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary-color) !important;
}

.grade-select .MuiInputLabel-root {
  color: var(--text-primary) !important;
}

.grade-select .MuiInputLabel-root.Mui-focused {
  color: var(--primary-color) !important;
}

.grade-select .MuiSelect-icon {
  color: var(--primary-color) !important;
}

.grade-select .MuiMenuItem-root {
  color: var(--text-primary) !important;
}

/* Delete class button styles */
.delete-class-container {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-end;
}

.delete-class-button {
  background-color: var(--error-color) !important;
  color: var(--text-primary) !important;
  text-transform: none !important;
  padding: 0.5rem 1.5rem !important;
}

.delete-class-button:hover {
  background-color: var(--error-dark) !important;
}

/* Delete class dialog styles */
.delete-class-dialog .MuiDialog-paper {
  background-color: var(--bg-secondary) !important;
  min-width: 400px;
}

.delete-class-dialog .MuiDialogTitle-root {
  color: var(--text-primary) !important;
  border-bottom: 1px solid var(--border-color);
  padding: 16px 24px !important;
}

.delete-class-dialog .MuiDialogContent-root {
  padding: 20px 24px !important;
}

.delete-class-dialog .MuiDialogContentText-root {
  color: var(--text-primary) !important;
  margin-bottom: 0 !important;
}

.delete-class-dialog .MuiDialogActions-root {
  padding: 16px 24px !important;
  background-color: var(--bg-secondary) !important;
  border-top: 1px solid var(--border-color);
}

.delete-class-dialog .cancel-button {
  color: var(--text-secondary) !important;
  text-transform: none !important;
}

.delete-class-dialog .confirm-delete-button {
  background-color: var(--error-color) !important;
  color: var(--text-primary) !important;
  text-transform: none !important;
  margin-left: 1rem !important;
}

.delete-class-dialog .confirm-delete-button:hover {
  background-color: var(--error-dark) !important;
}

/* Error and loading states */
.error-alert {
  margin-bottom: 1rem !important;
}

.no-classes-message {
  color: var(--text-secondary) !important;
  text-align: center;
  padding: 2rem;
}

/* Access denied alert */
.access-denied-alert {
  margin-top: 2rem;
}

/* Frozen column styles */
.frozen-column {
  position: sticky !important;
  background-color: var(--bg-tertiary) !important;
  box-sizing: border-box !important;
}

.frozen-column.student-name {
  left: 0 !important;
  z-index: 2 !important;
  min-width: 150px !important;
}

.frozen-column.activity-time {
  left: 150px !important;
  z-index: 2 !important;
  min-width: 120px !important;
}

.frozen-column.header-cell {
  z-index: 3 !important;
  background-color: var(--bg-primary) !important;
}

.frozen-column.header-cell.student-name {
  z-index: 4 !important;
}

.frozen-column.header-cell.activity-time {
  z-index: 4 !important;
}

.table-row:nth-of-type(odd) .frozen-column {
  background-color: var(--bg-secondary) !important;
}

.table-row:hover .frozen-column {
  background-color: var(--bg-primary) !important;
}

.frozen-column.activity-time::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: linear-gradient(to right, rgba(0,0,0,0.1), transparent);
  pointer-events: none;
}
