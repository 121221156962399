.feedback-long-division {
  position: relative;
  font-family: monospace;
  display: inline-block;
  padding: 1rem;
}

.feedback-long-division .division-structure {
  display: flex;
  align-items: flex-start;
}

.feedback-long-division .divisor {
  font-size: 1.2rem;
  margin-right: 0.25rem;
}

.feedback-long-division .main-structure {
  position: relative;
}

.feedback-long-division .quotient-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feedback-long-division .number {
  width: 2ch;
  height: 2ch;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.feedback-long-division .quotient-number {
  color: var(--success-color);
  font-weight: bold;
}

.feedback-long-division .subtracted-number {
  color: var(--error-color);
}

.feedback-long-division .remainder-number {
  color: var(--warning-color);
}

.feedback-long-division .step-line {
  border-top: 2px solid var(--text-primary);
  margin: 0.25rem 0;
  width: 100%;
}

.feedback-long-division .quotient-line {
  border-bottom: 2px solid var(--text-primary);
  width: 100%;
}

.feedback-long-division .division-bracket {
  border-left: 2px solid var(--text-primary);
  border-top: 2px solid var(--text-primary);
  width: 0.75rem;
  height: 2.5rem;
  position: absolute;
  left: 0;
  top: 1.5rem;
}

.feedback-long-division .steps-container {
  margin-left: 1rem;
  padding-top: 0.5rem;
}

.feedback-long-division .minus-sign {
  position: absolute;
  left: -1.5rem;
  font-size: 1.2rem;
}

.feedback-long-division .step {
  margin-bottom: 0.5rem;
  position: relative;
}

.feedback-long-division .final-remainder {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: 1rem;
  color: var(--success-color);
  font-weight: bold;
}
