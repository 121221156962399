/* Common dashboard styles */
.dashboard-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dashboard-paper {
  padding: 1rem;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dashboard-title {
  font-weight: 700 !important;
  color: var(--text-primary) !important;
  margin: 0 !important;
  font-size: 2rem !important;
}

.dashboard-error {
  margin-bottom: 1rem !important;
}

/* Loading styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

/* Stats styles */
.stats-paper {
  padding: 2rem;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
  margin-bottom: 2rem;
}

.stats-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: var(--text-primary) !important;
  margin-bottom: 1.5rem !important;
}

/* Grade Progress styles */
.grade-progress-card {
  padding: 2rem !important;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
}

.card-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: var(--text-primary) !important;
  margin-bottom: 1.5rem !important;
}

.grade-progress-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.school-level-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.school-level-title {
  color: var(--text-primary) !important;
  font-weight: 600 !important;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-color);
  margin-bottom: 0.5rem !important;
}

/* All sections use 5 columns */
.grade-progress-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.grade-progress-item {
  padding: 1rem;
  background-color: var(--bg-tertiary);
  border-radius: 8px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
  cursor: pointer;
  outline: none;
}

.grade-progress-item:hover, .grade-progress-item:focus {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  background-color: var(--bg-primary);
}

.grade-progress-item:focus-visible {
  box-shadow: 0 0 0 2px var(--primary-color);
}

.grade-progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.grade-name {
  color: var(--text-primary) !important;
  font-weight: 500 !important;
  font-size: 0.9rem !important;
}

.grade-percentage {
  color: var(--primary-color) !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

.grade-progress-bar {
  height: 6px !important;
  border-radius: 3px !important;
  background-color: var(--bg-primary) !important;
}

.grade-progress-bar .MuiLinearProgress-bar {
  background-color: var(--primary-color) !important;
}

/* Category styles */
.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.category-card {
  padding: 1.5rem;
  background-color: var(--bg-tertiary) !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.category-title {
  color: var(--text-primary) !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.category-progress-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.category-progress-text {
  color: var(--text-secondary) !important;
  font-size: 0.875rem !important;
}

.category-progress {
  width: 100%;
  height: 4px;
  background-color: var(--bg-primary);
  border-radius: 2px;
  overflow: hidden;
}

.category-progress-bar {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

.category-button {
  margin-top: auto !important;
  background-color: var(--primary-color) !important;
  color: var(--text-primary) !important;
  text-transform: none !important;
}

.category-button:hover {
  background-color: var(--primary-dark) !important;
}

.category-loading {
  margin: 2rem 0;
}

/* Practice time grid */
.practice-time-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.time-card {
  padding: 1rem !important;
  text-align: center;
  background-color: var(--bg-tertiary) !important;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
}

.time-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
  background-color: var(--bg-primary);
}

.time-card-title {
  color: var(--text-secondary) !important;
  font-size: 0.9rem !important;
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
}

.time-card-value {
  color: var(--primary-color) !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

/* Dialog styles */
.join-dialog .MuiDialog-paper {
  background-color: var(--bg-secondary) !important;
}

.join-dialog .MuiDialogTitle-root {
  color: var(--text-primary) !important;
}

.join-dialog .class-code-input .MuiInputLabel-root {
  color: var(--text-primary) !important;
}

.join-dialog .class-code-input .MuiInputBase-input {
  color: var(--text-primary) !important;
}

.join-dialog .class-code-input .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

.join-dialog .class-code-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-light) !important;
}

/* Error and loading states */
.error-alert {
  margin-bottom: 1rem !important;
}

.no-classes-message {
  color: var(--text-secondary) !important;
  text-align: center;
  padding: 2rem;
}

/* Access denied alert */
.access-denied-alert {
  margin-top: 2rem;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 1200px) {
  .grade-progress-item {
    padding: 0.75rem;
  }

  .grade-name, .grade-percentage {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 768px) {
  .grade-progress-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .practice-time-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .time-card {
    padding: 0.75rem !important;
  }

  .time-card-title {
    font-size: 0.8rem !important;
  }

  .time-card-value {
    font-size: 1.1rem !important;
  }
}
