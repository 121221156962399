.MuiButton-root {
  background-color: var(--primary-color) !important;
  color: var(--text-primary) !important;
  padding: 8px 16px !important;
  border-radius: 4px !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.MuiButton-root:hover {
  background-color: var(--primary-dark) !important;
}

.MuiButton-root:disabled {
  background-color: var(--secondary-light) !important;
  cursor: not-allowed !important;
}
