/* Display component styles */
.expression-display {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem;
  font-family: monospace;
}

.expression-text {
  font-size: 1.2rem;
  color: var(--text-primary);
}

.broken-expression {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.intermediate-steps {
  font-size: 1.2rem;
  color: var(--text-primary);
}

.final-result {
  font-size: 1.2rem;
  color: var(--text-primary);
}

/* Practice component styles */
.expression-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  margin: 32px 0;
  white-space: nowrap;
}

.expression-line {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expression-row {
  display: flex;
  align-items: center;
  gap: var(--gap-size);
  white-space: nowrap;
}

.expanded-result {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.partial-products {
  display: flex;
  align-items: center;
  gap: var(--gap-size);
}

.intermediate-sums {
  display: flex;
  align-items: center;
  gap: var(--gap-size);
  margin-left: var(--gap-size);
  justify-content: center;
  width: 100%;
}

.expression-text-large {
  color: var(--text-primary);
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
}

.input-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2px;
  white-space: nowrap;
}

.number-input {
  width: var(--input-width);
}

.hint-text {
  color: var(--text-secondary);
  margin-top: 4px;
  font-size: 0.65rem;
  white-space: nowrap;
}

/* Responsive sizing */
.three-digit {
  --gap-size: 8px;
  --input-width: 50px;
  --expression-font-size: 1.5rem;
}

.two-digit {
  --gap-size: 16px;
  --input-width: 60px;
  --expression-font-size: 2rem;
}
