.auth-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.auth-paper {
  width: 90%;
  max-width: 360px;
  background-color: var(--bg-primary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
  position: relative;
  padding: 2rem;
}

.auth-back-button {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.auth-title {
  text-align: center;
  margin-bottom: 2rem !important;
  color: var(--text-primary) !important;
  font-size: 2.125rem !important;
  font-weight: 400 !important;
}

.auth-error {
  margin-bottom: 1rem !important;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

/* Material-UI specific overrides */
.auth-form .MuiTextField-root,
.auth-form .MuiFormControl-root {
  width: 100%;
}

.auth-form .MuiInputBase-input,
.auth-form .MuiInputLabel-root,
.auth-form .MuiSelect-select {
  color: var(--text-primary) !important;
}

.auth-form .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
}

.auth-form .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-light) !important;
}

.auth-links {
  text-align: center;
  color: var(--text-secondary);
}

.auth-links a {
  color: var(--primary-light);
  text-decoration: none;
  margin-left: 0.5rem;
}
