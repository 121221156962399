.legal-container {
  min-height: calc(100vh - 200px);
  padding: 2rem 1rem;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.legal-paper {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
}

.legal-title {
  color: var(--primary-light) !important;
  font-weight: 600 !important;
  margin-bottom: 2rem !important;
}

.legal-section-title {
  color: var(--secondary-light) !important;
  font-weight: 500 !important;
}

.legal-text {
  color: var(--text-secondary) !important;
  line-height: 1.6 !important;
}

.legal-updated {
  color: var(--text-light) !important;
  font-style: italic !important;
}
