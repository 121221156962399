/* Base Layout */
.home-wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}
