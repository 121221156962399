.footer-paper {
  background-color: var(--bg-primary) !important;
  width: 100%;
  margin-top: auto;
  padding: 1.5rem 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer-container {
  max-width: 1200px !important;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-content {
  width: 100%;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
}

.footer-copyright {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.footer-links {
  color: var(--text-light) !important;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  align-items: center;
}

.footer-link {
  color: var(--text-light) !important;
  text-decoration: none !important;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.footer-link:hover {
  color: var(--primary-light) !important;
  text-decoration: none !important;
}

.footer-separator {
  color: var(--text-light);
  opacity: 0.6;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .footer-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    text-align: center;
  }

  .footer-links {
    justify-content: center;
  }

  .footer-container {
    padding: 0 1rem;
  }
}
