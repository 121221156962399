.completion-bar-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.completion-bar {
  flex: 1;
  height: 6px;
  background-color: var(--bg-tertiary);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.completion-progress {
  height: 100%;
  background: var(--primary-color);
  transition: width 0.3s ease;
}

.start-test-btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background-color: var(--primary-color);
  color: var(--text-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  min-width: fit-content;
}

.start-test-btn:hover {
  background-color: var(--primary-light);
}

.test-scores {
  background-color: var(--bg-secondary);
  padding: 2rem;
  border-radius: 12px;
  margin-top: 2rem;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
}

.scores-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.score-card {
  background-color: var(--bg-tertiary);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  position: relative;
  border: 1px solid var(--border-color);
}

.score-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.highest-score::before {
  background: var(--success-color);
}

.latest-score::before {
  background: var(--primary-color);
}

.score-card h3 {
  color: var(--text-primary);
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
}

.score-percentage {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0.5rem 0;
}

.score-details {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

/* Grade Test Page Styles */
.grade-test {
  max-width: 800px;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
  background-color: var(--bg-secondary);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
}

.test-header {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--text-primary);
}

.test-header h2 {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
}

.test-header p {
  color: var(--text-secondary);
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.test-complete {
  text-align: center;
  padding: 2rem;
  background-color: var(--bg-secondary);
  border-radius: 12px;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
  max-width: 600px;
  margin: 2rem auto;
}

.test-complete h2 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.test-complete p {
  color: var(--text-primary);
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

@media (max-width: 480px) {
  .completion-bar-container {
    flex-direction: column;
    gap: 1rem;
  }

  .test-header h2 {
    font-size: 1.5rem;
  }

  .score-percentage {
    font-size: 2rem;
  }

  .scores-container {
    grid-template-columns: 1fr;
  }

  .start-test-btn {
    width: 100%;
  }
}
