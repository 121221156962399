/* Hero Section */
.hero-section {
  margin: 0;
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, var(--bg-primary) 0%, var(--bg-secondary) 100%);
  z-index: -2;
}

/* Animated background pattern */
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, var(--primary-light) 0%, transparent 50%),
    radial-gradient(circle at 80% 80%, var(--secondary-light) 0%, transparent 50%);
  opacity: 0.1;
  animation: pulseBackground 8s ease-in-out infinite alternate;
  z-index: -1;
}

.hero-content-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
}

.hero-grid {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 4rem;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1/1;
  overflow: visible;
  margin: 0 auto;
  animation: floatImage 6s ease-in-out infinite;
}

.hero-image::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    transparent 0%,
    var(--primary-color) 25%,
    var(--secondary-color) 50%,
    var(--primary-color) 75%,
    transparent 100%
  );
  animation: rotateBorder 8s linear infinite;
  z-index: -1;
  opacity: 0.3;
  filter: blur(12px);
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  transform: scale(1);
  border-radius: 50%;
  box-shadow: var(--shadow-lg);
  mask-image: radial-gradient(circle at center, 
    black 30%,
    rgba(0, 0, 0, 0.9) 50%,
    rgba(0, 0, 0, 0.7) 65%,
    rgba(0, 0, 0, 0.4) 80%,
    transparent 90%
  );
  -webkit-mask-image: radial-gradient(circle at center, 
    black 30%,
    rgba(0, 0, 0, 0.9) 50%,
    rgba(0, 0, 0, 0.7) 65%,
    rgba(0, 0, 0, 0.4) 80%,
    transparent 90%
  );
}

.hero-img:hover {
  transform: scale(1.05);
}

.hero-text {
  text-align: left;
  max-width: 600px;
}

.hero-title {
  font-family: 'Poppins', sans-serif;
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 800;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: var(--shadow-sm);
  word-wrap: break-word;
  letter-spacing: -0.02em;
  position: relative;
  animation: slideDown 1s ease-out forwards;
}

.hero-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.8rem;
  width: 4em;
  height: 4px;
  background: var(--gradient-secondary);
  border-radius: 2px;
  animation: expandWidth 1.5s ease-out forwards;
}

.hero-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: 2.5rem;
  font-weight: 500;
  opacity: 0;
  animation: slideUp 1s ease-out forwards;
  animation-delay: 0.3s;
  word-wrap: break-word;
  letter-spacing: 0.01em;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  opacity: 0;
  animation: slideUp 1s ease-out forwards;
  animation-delay: 0.6s;
}

.btn {
  padding: 1rem 2rem !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  text-transform: none !important;
  border-radius: 12px !important;
  transition: all 0.3s ease !important;
}

.btn-primary {
  background: var(--gradient-primary) !important;
  color: white !important;
  box-shadow: 0 4px 15px rgba(67, 97, 238, 0.3) !important;
}

.btn-secondary {
  background: transparent !important;
  color: var(--text-primary) !important;
  border: 2px solid var(--primary-light) !important;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(67, 97, 238, 0.4) !important;
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expandWidth {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 4em;
    opacity: 1;
  }
}

@keyframes rotateBorder {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes floatImage {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes pulseBackground {
  0% {
    opacity: 0.05;
    transform: scale(1);
  }
  50% {
    opacity: 0.1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.05;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 960px) {
  .hero-section {
    padding: 3rem 0;
    min-height: auto;
  }

  .hero-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .hero-text {
    text-align: center;
    margin: 0 auto;
  }

  .hero-title::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .hero-image {
    max-width: 320px;
    order: -1;
  }

  .hero-buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 2rem 0;
  }

  .hero-content-wrapper {
    padding: 0 1.5rem;
  }

  .hero-grid {
    gap: 2rem;
  }

  .btn {
    padding: 0.8rem 1.5rem !important;
    font-size: 1rem !important;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 1.5rem 0;
  }

  .hero-content-wrapper {
    padding: 0 1rem;
  }

  .hero-grid {
    gap: 1.5rem;
  }

  .hero-image {
    max-width: 240px;
  }

  .hero-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .btn {
    width: 100%;
  }
}
