.vertical-operation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  min-height: 200px;
}

.operation-numbers {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: 'Courier New', monospace;
  position: relative;
  padding: 0 0.5rem;
  width: 120px;
}

/* Practice view number styles */
.vertical-operation .number {
  font-size: 1.6rem !important;
  line-height: 1.2 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  margin: 0.2rem !important;
  min-width: 2.5ch;
  text-align: right;
}

.operation-line-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: relative;
}

.operation-symbol {
  font-size: 1rem !important;
  line-height: 1 !important;
  color: var(--text-primary) !important;
  font-weight: bold !important;
  margin: 0 !important;
  position: absolute;
  left: -1.5rem;
}

.operation-line {
  width: 100%;
  height: 2px;
  background-color: var(--text-primary);
  margin: 0.5rem 0;
}

/* Input styles */
.vertical-operation input {
  width: 32px !important;
  height: 32px !important;
  text-align: center;
  font-size: 1rem;
  font-family: 'Courier New', monospace;
  font-weight: bold;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0;
  margin: 0 2px;
  box-sizing: border-box;
}

/* Shift carry boxes for multiplication */
.vertical-operation .carries-row {
  margin-left: 36px;
}

.vertical-operation input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-color-light);
}

/* Feedback dialog styles */
.feedback-vertical-operation .number {
  font-size: 1.2rem !important;
  position: relative;
}

.feedback-vertical-operation .number.strike {
  text-decoration: line-through;
}

.feedback-vertical-operation .carry-number {
  font-size: 1rem !important;
  color: var(--text-secondary) !important;
}

.feedback-vertical-operation .answer-number {
  color: var(--success-color) !important;
}

.feedback-vertical-operation .intermediate-number {
  color: var(--text-secondary) !important;
}
