.grade-landing {
  width: 95%;
  margin: 0 auto;
  padding: 1rem;
}

.grade-header {
  margin-bottom: 1.5rem;
}

.grade-header h1 {
  color: var(--text-primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: left;
}

.completion-status {
  background: var(--bg-secondary);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.completion-text {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.completion-bar {
  height: 8px;
  background: var(--bg-primary);
  border-radius: 4px;
  overflow: hidden;
}

.completion-progress {
  height: 100%;
  background: var(--primary-color);
  transition: width 0.3s ease;
}

.topics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.topic-card {
  padding: 1.2rem;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.topic-card h2 {
  color: var(--text-primary);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
}

.levels-list {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 0;
  flex-wrap: wrap;
}

.level-square {
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
  flex: 0 0 45px;
  background: var(--bg-primary);
  border: 2px solid var(--text-primary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.level-square.completed {
  background: var(--primary-color);
  border-color: var(--text-primary);
}

.level-square.quiz {
  border-color: var(--primary-color);
}

.level-square.quiz.completed {
  background: var(--primary-color);
}

.level-square .completion-count {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.level-square:hover {
  transform: translateY(-2px);
}

.level-square::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background: var(--bg-primary);
  color: var(--text-primary);
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  border: 1px solid var(--border-color);
  z-index: 1000;
}

.level-square:hover::before {
  visibility: visible;
  opacity: 1;
  bottom: calc(100% + 5px);
}

.error-message {
  color: var(--danger-color);
  text-align: center;
  padding: 1rem;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-primary);
}

@media (max-width: 768px) {
  .grade-landing {
    margin: 0.5rem auto;
  }
  
  .topics {
    grid-template-columns: 1fr;
  }
}
