@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

.header {
  background-color: var(--bg-primary) !important;
  box-shadow: var(--shadow-sm) !important;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1rem !important;
  position: relative !important;
}

.header-menu-container {
  position: relative;
  display: inline-block;
}

.header-menu-container svg {
  color: var(--text-primary) !important;
}

.header-menu-container:hover svg {
  color: var(--primary-color) !important;
}

.header-menu-container:hover .header-menu {
  display: block;
}

.header-menu {
  position: absolute;
  top: 100%;
  padding: 0.5rem 0;
  min-width: 200px;
  z-index: 1000;
  display: none;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md);
}

.header-menu.left {
  left: 0;
}

.header-menu.right {
  right: 0;
}

.menu-item-container {
  position: relative;
}

.menu-item-container:hover .submenu {
  display: block;
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  min-width: 180px;
  background-color: var(--bg-secondary) !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md);
  display: none;
  z-index: 1001;
  padding: 0.5rem 0;
}

.header-brand {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  transition: transform 0.2s ease;
  gap: 0.75rem;
}

.header-brand:hover {
  transform: translateX(-50%) scale(1.05);
}

.header-logo {
  height: 2.5rem;
  width: auto;
  object-fit: contain;
  border-radius: 4px;
}

.header-title {
  color: var(--text-primary) !important;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 600 !important;
  letter-spacing: 1.5px !important;
  font-size: 1.5rem !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.header-auth-buttons {
  display: flex;
  gap: 1rem;
}

@media screen and (max-width: 768px) {
  .header-auth-buttons {
    display: none;
  }

  .header-logo {
    height: 2rem;
  }
  
  .header-title {
    font-size: 1.2rem !important;
  }
}

.header-icon-container {
  display: flex;
  align-items: center;
  min-width: 40px;
}

.menu-item {
  padding: 0.75rem 1rem !important;
  color: var(--text-secondary) !important;
  background-color: var(--bg-secondary) !important;
  position: relative;
  white-space: nowrap;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 1rem;
}

.menu-item:hover {
  background-color: var(--bg-tertiary) !important;
  color: var(--primary-color) !important;
}

.grade-label {
  flex: 1;
}

.grade-progress {
  color: var(--primary-light);
  font-weight: 500;
  font-size: 0.9em;
  opacity: 0.9;
}
