/* Benefits Section */
.benefits-section {
  padding: 6rem 0;
  background: var(--bg-primary);
  position: relative;
  overflow: hidden;
  width: 100%;
}

/* Animated background pattern */
.benefits-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 90% 10%, var(--secondary-color) 0%, transparent 40%),
    radial-gradient(circle at 10% 90%, var(--primary-color) 0%, transparent 40%);
  opacity: 0.05;
  animation: shimmerBackground 15s ease-in-out infinite alternate;
}

.benefits-section .section-title-wrapper {
  text-align: center;
  position: relative;
}

.benefits-section .section-title {
  font-family: 'Poppins', sans-serif;
  font-size: clamp(2rem, 4vw, 2.5rem);
  font-weight: 700;
  color: var(--text-primary);
  margin: 0 0 1rem;
  position: relative;
  display: inline-block;
}

.benefits-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--gradient-secondary);
  border-radius: 2px;
}

.benefits-stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.stats-loading {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-loading .MuiCircularProgress-root {
  color: var(--primary-light) !important;
}

.stat-item {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  background: linear-gradient(145deg, 
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.02) 100%
  );
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  animation: fadeInUp 0.6s ease-out forwards;
}

.stat-item:nth-child(1) { animation-delay: 0.1s; }
.stat-item:nth-child(2) { animation-delay: 0.2s; }
.stat-item:nth-child(3) { animation-delay: 0.3s; }

.stat-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradient-primary);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.stat-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.stat-item:hover::before {
  opacity: 0.05;
}

.stat-number {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  position: relative;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: countUp 2s ease-out forwards;
}

.stat-label {
  display: block;
  font-family: 'Inter', sans-serif;
  font-size: 1.1rem;
  color: var(--text-light);
  position: relative;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmerBackground {
  0% {
    opacity: 0.03;
    transform: scale(1);
  }
  50% {
    opacity: 0.05;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.03;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 960px) {
  .benefits-section {
    padding: 4rem 0;
  }

  .benefits-stats {
    gap: 1.5rem;
    padding: 1.5rem;
  }

  .stat-item {
    padding: 1.5rem;
    min-width: 200px;
  }
}

@media (max-width: 768px) {
  .benefits-section {
    padding: 3rem 0;
  }

  .stat-item {
    flex: 0 1 calc(50% - 1rem);
    min-width: 180px;
  }
}

@media (max-width: 480px) {
  .benefits-section {
    padding: 2rem 0;
  }

  .benefits-stats {
    gap: 1rem;
    padding: 1rem;
  }

  .stat-item {
    flex: 1 1 100%;
    min-width: unset;
    padding: 1.2rem;
  }

  .stat-number {
    font-size: 2.5rem;
  }

  .stat-label {
    font-size: 1rem;
  }
}
