:root {
  /* Primary colors - Vibrant blue */
  --primary-color: #4361ee;
  --primary-light: #4895ef;
  --primary-dark: #3730a3;

  /* Secondary colors - Energetic orange */
  --secondary-color: #f72585;
  --secondary-light: #ff4d6d;
  --secondary-dark: #c81d5e;

  /* Background colors - Modern dark theme */
  --bg-primary: #0f172a;
  --bg-secondary: #1e293b;
  --bg-tertiary: #334155;

  /* Text colors - Crisp contrast */
  --text-primary: #f8fafc;
  --text-secondary: #e2e8f0;
  --text-light: #94a3b8;

  /* Border colors - Subtle definition */
  --border-color: #475569;
  --border-light: #64748b;
  --border-dark: #334155;

  /* Status colors - Clear feedback */
  --success-color: #10b981;
  --warning-color: #f59e0b;
  --danger-color: #ef4444;
  --info-color: #3b82f6;

  /* Shadow - Refined depth */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.3);
  --shadow-md: 0 4px 8px rgba(0, 0, 0, 0.35);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.4);

  /* Gradient */
  --gradient-primary: linear-gradient(135deg, #4361ee 0%, #4895ef 100%);
  --gradient-secondary: linear-gradient(135deg, #f72585 0%, #ff4d6d 100%);
}
