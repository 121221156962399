/* Features Section */
.features-section {
  padding: 6rem 0;
  background: var(--bg-secondary);
  position: relative;
  overflow: hidden;
}

/* Background decoration */
.features-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, 
    transparent 0%,
    var(--primary-light) 50%,
    transparent 100%
  );
  opacity: 0.3;
}

/* Bottom transition gradient */
.features-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom,
    transparent 0%,
    var(--bg-primary) 100%
  );
  pointer-events: none;
}

.section-title-wrapper {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
}

.section-title {
  font-family: 'Poppins', sans-serif;
  font-size: clamp(2rem, 4vw, 2.5rem);
  font-weight: 700;
  color: var(--text-primary);
  margin: 0 0 1rem;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--gradient-primary);
  border-radius: 2px;
}

.feature-card {
  background: linear-gradient(145deg, 
    rgba(255, 255, 255, 0.05) 0%,
    rgba(255, 255, 255, 0.02) 100%
  ) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px !important;
  padding: 2.5rem 2rem !important;
  height: 80%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  text-align: center;
  transition: all 0.3s ease-in-out !important;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at 50% 0%, 
    var(--primary-color) 0%, 
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 0;
}

.feature-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2) !important;
}

.feature-card:hover::before {
  opacity: 0.1;
}

.feature-icon {
  background: var(--gradient-primary);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 16px rgba(67, 97, 238, 0.2);
}

.feature-card:hover .feature-icon {
  transform: scale(1.1);
}

.feature-icon svg {
  color: white;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.feature-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 1rem;
  position: relative;
  z-index: 1;
}

.feature-description {
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-light);
  margin: 0;
  position: relative;
  z-index: 1;
}

/* Animation for cards on scroll */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-card {
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
}

.feature-card:nth-child(1) { animation-delay: 0.1s; }
.feature-card:nth-child(2) { animation-delay: 0.2s; }
.feature-card:nth-child(3) { animation-delay: 0.3s; }
.feature-card:nth-child(4) { animation-delay: 0.4s; }

/* Responsive Design */
@media (max-width: 960px) {
  .features-section {
    padding: 4rem 0 6rem;
  }

  .section-title-wrapper {
    margin-bottom: 3rem;
  }

  .feature-card {
    padding: 2rem !important;
  }
}

@media (max-width: 768px) {
  .features-section {
    padding: 3rem 0 5rem;
  }

  .feature-card {
    padding: 1.5rem !important;
    margin-bottom: 1.5rem;
  }

  .feature-icon {
    width: 60px;
    height: 60px;
  }

  .feature-icon svg {
    font-size: 30px !important;
  }

  .feature-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 2rem 0 4rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .feature-card {
    margin-bottom: 1rem;
  }
}
